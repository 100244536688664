<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-icon left>mdi-chat-question</v-icon>
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Hub FAQ ({{ totalFaqs }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn
        v-if="hasCreatePermission"
        color="primary"
        class="appbar__btn"
        height="40px"
        :to="{ name: 'hub.faq.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Create FAQ
      </v-btn>
    </v-app-bar>

    <div
      class="mx-4"
      id="faq-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-spacer />
        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search building and title"
          @input="search"
        ></v-text-field>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="false"
              class="ml-10"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Bulk Actions
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in actions" :key="index">
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-data-table
        :headers="headers"
        :items="faqs"
        v-model="selected"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        :loading="loading"
        @click:row="editItem"
        @update:options="fetch"
        :server-items-length="totalFaqs"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
        :items-per-page.sync="itemsPerPageTables"
        @pagination="handleScrollAfterTableRender"
      >
        <template v-slot:item.building="{ item }">
          <div class="text-truncate" style="max-width: 200px">
            {{ item.buildings.map((building) => building.name).join(', ') }}
          </div>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div v-if="item.addedDate">{{ item.addedDate }}</div>
        </template>
        <template v-slot:item.updated_at="{ item }">
          <div v-if="item.updatedDate">{{ item.updatedDate }}</div>
        </template>
      </v-data-table>
      <div
        class="d-flex justify-center mt-3"
        v-if="!(faqs.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Hub FAQ list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  name: 'FaqsPage',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      selected: [],
      defaultFilter: 'title',
      sortOrder: false,
      filterBy: [
        {
          value: 'title',
          label: 'Title',
        },
        {
          value: 'created_at',
          label: 'Added date',
        },
      ],
      headers: [
        {
          text: 'Title',
          value: 'title',
          width: '55%',
        },
        {
          text: 'Buildings',
          value: 'building',
          width: '15%',
        },
        {
          text: 'Added date',
          value: 'created_at',
          width: '15%',
        },
        {
          text: 'Last Updated',
          value: 'updated_at',
          width: '15%',
        },
      ],
      actions: [
        {
          value: 'delete',
          label: 'Delete',
        },
      ],
      pageTables: 0,
      itemsPerPageTables: 0,
    }
  },

  created() {
    this.clearFaqs()
    this.fetchInitParams()
  },

  beforeRouteLeave(to, from, next) {
    this.setScrollPosition(window.scrollY)
    next()
  },

  computed: {
    ...mapState({
      faqs: (state) => state.faq.list,
      listMeta: (state) => state.faq.listMeta,
      permissions: (state) => state.auth.permissions,
      totalFaqs: (state) => state.faq.totalFaqs,
      paramsFaq: (state) => state.faq.queryParams,
      scrollPosition: (state) => state.faq.scrollPosition,
    }),

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },

    hasCreatePermission() {
      return validatePermissions([PERMISSION.FAQS_CREATE], this.permissions)
    },
  },

  methods: {
    ...mapActions({
      getFaqs: 'faq/getFaqs',
    }),

    ...mapMutations({
      clearFaqs: 'faq/clearFaqList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
      setParams: 'faq/setQueryParams',
      setScrollPosition: 'faq/setScrollPosition',
    }),

    fetchInitParams() {
      if (this.paramsFaq?.sort) {
        if (this.paramsFaq.sort[0].includes('-')) {
          this.defaultFilter = this.paramsFaq.sort.slice(1)
          this.sortOrder = true
        } else {
          this.defaultFilter = this.paramsFaq?.sort
          this.sortOrder = false
        }
      } else {
        this.defaultFilter = 'title'
      }

      if (this.paramsFaq?.itemsPerPage) {
        const { itemsPerPage } = this.paramsFaq
        this.itemsPerPageTables = [25, 50, 100].includes(itemsPerPage)
          ? itemsPerPage
          : -1
      }

      if (this.paramsFaq?.search) {
        this.filter.search = this.paramsFaq.search
      }

      this.fetch(null, 1, this.paramsFaq)
    },

    filterList(filterValue) {
      this.clearFaqs()
      this.fetch()
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetch(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearFaqs()
      this.fetch()
    }, 600),

    async fetch(options, page = 1, paramsFaq = null) {
      this.setLoadingBuilding(true)
      if (this.loading) return
      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalFaqs
            : options.itemsPerPage
          : 25,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      await this.getFaqs(paramsFaq || params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
          this.scrollToPosition()
        })
      this.setParams(params)
    },
    editItem(item) {
      this.$router.push({
        name: 'hub.faq.details',
        params: { id: item.id },
      })
    },

    handleScrollAfterTableRender(pagination) {
      if (pagination.pageCount === 0) return
      this.scrollToPosition()
    },

    scrollToPosition() {
      const scrollPosition = this.scrollPosition

      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}
.v-list-item__title {
  cursor: pointer;
}
</style>
